/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { FaStar } from 'react-icons/fa6';
import { GET_LANDMARK } from '../../actions/types';
import { IScene } from '../../types/scene';
import map from './images/map.svg';
import pin from './images/pin.svg';
import logo from '../../assets/images/logo-white.png';
import styles from './ShowroomNav.module.scss';

const SceneLink = ({ link, close }: { link: IScene, close: () => void }) => {
  const isPicture = link.picture;
  const params = useParams();
  const isActive = params.sceneId === link._id;
  return (<Link to={`/showroom/${link._id}`} onClick={() => close()}
    className={`${styles.link} ${isPicture ? '' : styles.disabled} ${isActive ? styles.active : ''}`}>

    <h2>{link.name}</h2>
    {link?.isNovelties
      && <div className={styles.new}>
        <FaStar /> <p>Nouveauté</p>
      </div>
    }
    {isActive
      && <div className={styles.pin}>
        <img src={pin} alt="Vous êtes ici" />
        <p>Vous êtes ici</p>
      </div>
    }
  </Link>);
};

export default function ShowroomNav({ defaultActive = false }) {
  const [isActive, setIsActive] = useState<boolean>(defaultActive);
  const dispatch = useDispatch();
  const { scenes, landmark } = useSelector((d: any) => d.scenesReducer);
  useEffect(() => {
    if (isActive && landmark) {
      dispatch({
        type: GET_LANDMARK,
        payload: null,
      });
    }
  }, [isActive]);
  return (
    <>
      {!defaultActive
        && <button
          onClick={() => setIsActive(true)}
          className={styles.toggle}>
          <img src={map} alt="nav" />
        </button>
      }
      <div className={`${styles.container} ${isActive ? styles.active : ''}`}>
        <div className={styles.logo}>
          <img src={logo} alt="logo-super-u" />
        </div>
        <h1 className={styles.title}>Bienvenue dans notre showroom Coopérative U !</h1>
        <p>Découvre ici nos différents espaces</p>
        {scenes
          && <div className={styles.box}>
            <div className={styles.top}>
              <div className={styles.col}>
                <SceneLink link={scenes[7]} close={() => setIsActive(false)} />
              </div>
              <div className={styles.col}>
                <SceneLink link={scenes[6]} close={() => setIsActive(false)} />
              </div>
              <div className={styles.col}>
                <SceneLink link={scenes[5]} close={() => setIsActive(false)} />
              </div>
              <div className={styles.col}>
                <SceneLink link={scenes[4]} close={() => setIsActive(false)} />
              </div>
              <div className={styles.col}>
                <SceneLink link={scenes[3]} close={() => setIsActive(false)} />
              </div>
              <div className={`${styles.col} ${styles.last}`}>
                <div className={styles.item}>
                  <SceneLink link={scenes[8]} close={() => setIsActive(false)} />
                </div>
                <div className={styles.item}>
                  <SceneLink link={scenes[2]} close={() => setIsActive(false)} />
                </div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div className={styles.col}>
                <SceneLink link={scenes[11]} close={() => setIsActive(false)} />
              </div>
              <div className={styles.col}>
                <SceneLink link={scenes[9]} close={() => setIsActive(false)} />
              </div>
              <div className={styles.col}>
                <SceneLink link={scenes[10]} close={() => setIsActive(false)} />
              </div>
              <div className={styles.col}>
                <SceneLink link={scenes[1]} close={() => setIsActive(false)} />
              </div>
              <div className={styles.col}>
                <SceneLink link={scenes[0]} close={() => setIsActive(false)} />
              </div>
            </div>

          </div>
        }
      </div>
    </>
  );
}
