import bas from './images/BAS.svg';
import culture from './images/culture.svg';
import cuisine from './images/cuisine.svg';
import auto from './images/auto.svg';
import saisonnier from './images/saisonnier.svg';
import service from './images/service.svg';
import bagages from './images/bagages.svg';
import tiroirs from './images/tiroirs.svg';
import game from './images/game.svg';
import u from './images/u.svg';
import proxy from './images/proxy.svg';

const scenesProperties = [
  {
    icon: service,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: u,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: culture,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: bas,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: cuisine,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: bagages,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: auto,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: saisonnier,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: game,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: tiroirs,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: tiroirs,
    position: {
      x: 200,
      y: 200,
    },
  },
  {
    icon: proxy,
    position: {
      x: 200,
      y: 200,
    },
  },
];

export default scenesProperties;
